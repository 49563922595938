/* body {
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    background: #fff;
} */

body {
    color: #000;
    background: #fff;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

header {
    z-index: 90;
    position: sticky;
    top: 0;
}

main {
    flex: 1;
}

/* .jss2 {
    padding: 0px !important;
} */

.MuiPickersDay-daySelected:focus {
    background: #3f51b5 !important;
}

.MuiPickersSlideTransition-transitionContainer {
    overflow: hidden !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: gold !important
}

.MuiLinearProgress-colorPrimary {
    background-color: #f8e7c0 !important
}

.boxSec .makeStyles-paper-27 .makeStyles-paper-52 {
    padding: 0px;
}

.boxSec {
    float: left;
    width: 100%;
}

.tabsection .form-horizontal .control-label {
    font-weight: 600;
    color: #000 !important
}

.tabsection .PrivateSwitchBase-root-9 {
    padding: 0px 3px;
}

.labelColor {
    color: #000
}

.dropdownPlaceholder {
    border-radius: 6px;
}

.dropdownPlaceholder select {
    margin-left: 10px;
    color: #c5c5c5 !important;
    font-size: 15px;
}

.dropdownPlaceholder select option {
    color: #000 !important;
    margin-left: 10px;
    font-size: 15px;
}

.datetimePlaceholder input {
    color: #c5c5c5 !important;
    margin-left: 10px;
    font-size: 15px;
}

/* .tabsection .form-horizontal .controls .control-label{font-weight:600;} */

.tabsection .MuiBox-root {
    padding: 24px;
    background: #fafafa;
    border: 1px solid #eee;
}

.tabsection button:focus {
    outline: none;
    background-color: #ffb400;
    color: #2b2b2b;
}

.reqbtn {
    /* background: #c52c02; */
    border-radius: 13px;
    padding: 5px 10px;
    text-align: center;
    width: fit-content;
    min-width: 80px;
    display: flex;
    justify-content: center;
    color: #fff;
}

.treatmentstatus {
    margin-right: 80px;
    background: #c52c02;
    border-radius: 13px;
    padding: 5px 0px 5px 0px;
    text-align: center;
    width: 120px;
    color: #fff;
}

.astprTreatmentStatus {
    background: #c52c02;
    border-radius: 13px;
    padding: 5px 10px;
    text-align: center;
    width: fit-content;
    color: #fff;
}

/* .MuiButtonGroup-root {
    background: #fff;
    background-color: #ffb500;
} */

.MuiButtonGroup-root button {
    color: #000 !important;
    font-weight: bold;
}

/* Navigation Styles --Start*/

.active {
    border-bottom: 3.5px solid #ffb400
}

/* Navigation Styles  --End*/

/*start for control*/

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
    height: 1rem;
    font-size: 15px;
}

.MuiPaper-root {
    padding: 0px;
    /* background-color: #fafafa !important; */
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border: none;
    box-shadow: none;
}

.MuiInputBase-input {
    color: #000 !important;
    margin-left: 10px !important;
    font-size: 15px !important;
    border-bottom: none !important;
}

.chklabel {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.controllabel {
    color: #000;
    font-weight: 600;
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr auto;
}

.controllabelmargin {
    color: #000;
    font-weight: 600;
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 8px !important;
}

.controllabelCreate {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
}

.controlvalue {
    color: #000;
    font-size: 14px;
}

/*start*/

.form-horizontal .control-group,
.form-vertical .control-group {
    margin-bottom: 0
}

.form-horizontal .control-group+.control-group,
.form-vertical .control-group+.control-group {
    margin-top: 0px;
    padding-top: 0px;
    border-top: 0px solid #f5f5f5;
}

.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
    display: table;
    content: "";
    line-height: 0;
}

.form-horizontal .control-group:after {
    clear: both;
}

.form-horizontal .control-label {
    float: left;
    width: 175px;
    padding-top: 5px;
    text-align: left;
    font-size: 14px;
    color: #2b2b2b;
    font-weight: 600;
}

.form-horizontal .controls .control-label {
    font-size: 14px;
    color: #2b2b2b;
    font-weight: 400;
}

.form-horizontal .controls {
    display: inline-block;
    padding-left: 20px;
    margin-left: 180px;
    margin-left: 0;
}

.boxsecnew {
    border: 1px solid #ddd;
    background: #fff;
    float: left;
    width: 100%;
    box-shadow: 2px 2px 5px #ccc8c8;
}

.boxHeading {
    background: #fafafa;
    padding: 10px 20px;
    float: left;
    width: 100%;
    border-bottom: 2px solid #dddddd;
}

.paddmargin0 {
    margin: 0px 0px;
    padding: 0px;
}

.subhead {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px;
}

.headno {
    font-size: 23px;
    font-weight: 600;
    line-height: 20px;
}

.boxection {
    padding: 10px 20px;
    background: #fafafa;
    border: 1px solid #ddd;
    border-top: none;
    border-left: none;
    border-right: none;
}

/*form end*/

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
    border-bottom: 0px;
}

.margin8 {
    margin: 6px 8px;
}

.MuiSelect-selectMenu {
    min-height: 30px;
}

/* .datebox .MuiFormControl-root {
        margin-bottom: 10px !important;margin-top: 8px;
    } */

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: 0px;
    padding-left: 0px
}

.forLabel {
    width: 100%;
    float: left;
}

.internalComment {
    color: red;
    font-size: 12px;
    margin: 0px 8px;
}

.boxSec .multiline label+.MuiInput-formControl {
    margin-bottom: -2px;
}

.MuiTabs-flexContainer {
    display: block !important;
}

.grey-color button {
    background: #EEEDED !important
}

.padding12 {
    padding: 12px;
}

.MuiInput-underline:before {
    border-bottom: 1px solid rgb(167 159 159 / 42%) !important;
}

.pageHead {
    background: #fafafa;
    padding: 12px 16px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
}

.boxshadow {
    background: #fff;
    padding: 12px 10px;
    width: 100%;
    margin: 0px 25px;
}

.boxSec .MuiPaper-elevation1 {
    box-shadow: none;
}

.posrel {
    position: relative;
}

.MuiFormLabel-root {
    color: blue;
}

.MuiInputBase-root {
    background: #fff;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 15px !important;
    height: 45px;
}

.multilineHeight>div {
    height: 75px;
}

.multilineHeightalight>div {
    height: 75px;
    text-align: right;
}

.multilineHeightalight>div>textarea {
    text-align: right;
    margin-right: 10px;
}

.chkheight>div {
    /* width: 100px; */
    display: inline-block;
    margin: 18px 0;
}

.chkheightView>div {
    width: 100px;
    display: inline-block;
}

/*ended by Mayadevi 11-9-2020*/

.header {
    background: #ffb400
}

.logo {
    width: 177px;
}

.icon-block {
    padding: 0 15px;
}

.icon-block .material-icons {
    font-size: inherit;
}

.teal-color {
    background-color: #4c7d7a;
}

.teal-color ul li:hover {
    background-color: #b06d04;
}

.username-section {
    margin-top: 15px;
}

nav {
    display: grid;
    height: 50px;
    line-height: 50px;
}

nav ul a {
    transition: background-color .3s;
    font-size: 14px;
    color: #fff;
    display: block;
    padding: 0px 19px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
}

/* .MuiSnackbar-anchorOriginBottomCenter{
        bottom: 70%;
    } */

.header a img {
    width: 177px;
}

.username-section a {
    color: #000;
    font-size: 14px;
}

.sidenav.open {
    left: 230px;
    top: 110px;
}

@media screen and (min-width: 993px) {
    .mobile-logo {
        display: none !important;
    }

    .nav-wrapper {
        background: #000000;
    }
}

@media screen and (max-width: 993px) {
    .nav-wrapper {
        background: #ffb400;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 0px !important;
    }

    .MuiTab-root>span {
        margin: 0 20px;
    }
}

@media screen and (max-width:680px) {
    .header .row .col.s10 {
        width: 50%;
    }

    .header .row .col.s2 {
        width: 50%;
    }

    nav .sidenav-trigger i {
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
    }
}

.pagetitleHead {
    border-bottom: 1px solid #ddd;
    background: #fafafa;
}

.darkGreyBG {
    background: #eaeaea;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.pagetitleHeadPadding div {
    padding-bottom: 10px !important;
}

.backgroundgreycolor {
    background: #fafafa;
}

.pagetitleHead p {
    font-size: 14px;
    font-weight: 600;
}

.MuiInputBase-root.Mui-disabled {
    background: #f3f3f3;
    border: 1px solid #ecedec;
}

.rFloat {
    float: right;
}

.lFloat {
    float: left;
}

.margin10px {
    margin: 10px;
}

.borderbottom {
    border-bottom: 1px solid #dbddd9;
}

.cardPadding {
    padding: 7px 0px 0px 17px !important
}

.customTab {
    box-shadow: none !important;
    background: #fafafa;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.MuiTabs-indicator {
    background-color: #ffb500 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #000 !important;
    font-weight: bold;
    background: #fff;
}

.chkPadding {
    padding-left: 8px;
}

.MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}

.logoutButton>span {
    text-transform: none;
}

.logoutMenuItem {
    padding: 0 40px !important;
}

button:focus {
    background-color: #fff !important;
}

button:selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.tabpanelTreatmentPlan>div {
    padding: 0px !important;
}

.caseCard {
    margin: "-10px";
}

.caseCard h5 {
    color: black;
}

.caseCard-Details {
    line-height: 1em;
    font-size: 13.5px;
    margin-top: -10px;
}

.caseCard-Details p {
    margin-bottom: -8px;
    color: grey
}

.caseCard-Details span {
    color: black
}

.tprCaseDetailsPanel {
    /* margin: 0 10px;
    background: #fafafa; */
    margin: 10px;
    background: #ffffff;
    border-style: solid;
    border-width: thin;
    border-color: #a5a5a5;
}

.paddingbotton10 {
    padding-bottom: 10px;
}

.hide {
    display: none;
}

.display {
    display: block;
}

th.MuiTableCell-root.MuiTableCell-head {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

th.MuiTableCell-root.MuiTableCell-body a {
    color: blue;
}

th.MuiTableCell-root.MuiTableCell-body a {
    color: blue;
}

/* ADD Sets Page Start */

.addsetsControl {
    width: 100%;
    margin: 0;
    padding: 0;
}

.addsetsControl tr th {
    margin: 0;
    padding: 0;
    text-align: left;
    border-bottom: 1px solid #dddddb;
    font-size: 14px;
}

.addsetsControl tr td {
    margin: 10px;
    padding: 0;
    border-bottom: 1px solid #eaeae7;
}

.addsetsControl tr td div {
    margin: 2px 0 !important;
}

.filter-text {
    margin: 0 6px;
}

.searchBar>div>div {
    height: 40px;
}

.not-switch:nth-child(2) {
    min-width: 400px;
}

.NotificationProgress {
    text-align: center;
    margin-top: 200px;
}

.emailnotification {
    min-width: 415px;
}

/* .rightalign> form> div>textarea {
    text-align: right;
} */

#External#Comments {
    text-align: right !important;
}

/*Pricing*/
.h3 {
    margin-Left: 50px;
    margin-Top: 20px;
}

.pricing {
    position: relative;
    left: 40px;
    top: 150px;
}

.span {
    margin-left: 30px;
    padding: 10px;
}

.button {
    top: "30px"
}

.contractBtn {
    background: #ffb500 !important;
    width: 30%;
}

.multipleContractBox {
    width: 100%;
    margin: 50px 280px !important;
}

.multipleContractMsg {
    text-align: center !important;
    padding: 30px;
}

.overrideInput {
    border: none;
    padding-bottom: 3px;
    margin-bottom: 5px;
    border-bottom: 1px solid grey !important;
}

.qqCodeSaveMsgContainer {
    margin-top: 30px;
}

.qqCodeSaveMsg {
    width: 30%;
    text-align: center;
    margin: auto
}

.qqLabel {
    padding: 5px;
}

.qqSelectField {
    border: #000000;
    width: 300px;
    border-radius: 3px;
}

.qqRationalLable {
    padding-top: 50px;
}

.qqRationalField {
    padding: 5px;
}

.qqSaveButton {
    background-color: #ffb500 !important;
    width: 30%;
    position: relative;
    top: 30px;
}
#parts label + .MuiInput-formControl {
    margin-top: 0px;
}

#parts .MuiCardContent-root {
 padding: 7px;
    }

#parts .MuiTableCell-root {
display: table-cell;
padding:7px;
    }

#parts .MuiTableCell-sizeSmall {
    font-size: 15px;
 padding: 6px 9px 6px 12px;
    }

    #parts2 tr.MuiTableRow-root.MuiTableRow-head th:nth-child(8n) {
         width:78px;
        }

#parts3 .MuiCardContent-root {
            padding: 20px 0px;
        }

#parts .MuiFormControlLabel-root {
 cursor: pointer;
display: inline-flex;
align-items: center;
 margin-left: 0px;
margin-right: 0px;
vertical-align: middle;
 -webkit-tap-highlight-color: transparent;
}

#parts .MuiTableCell-alignLeft {
 text-align: center;
}

#parts2 .MuiCardContent-root {
    padding: 0px;
}

#parts tr.MuiTableRow-root.MuiTableRow-head th:nth-child(8n) {
 width:126px;
 }
.qqCodeSaveMsgContainer {
    margin-top: 30px;
}

.qqCodeSaveMsg {
    width: 30%;
    text-align: center;
    margin: auto
}

.qqCodeMainContainer {
    padding: 60px;
    margin: 20px;
    /* width: 60vh;
    height: 70vh; */
}

.qqLabel {
    padding: 5px;
}

.qqSelectField {
    border: #000000;
    width: 300px;
    border-radius: 3px;
}

.qqRationalLable {
    padding-top: 50px;
}

.qqRationalField {
    padding: 5px;
    width: 300px;
    resize: none;
}

.qqSaveButton {
    background-color: #ffb500 !important;
    width: 30%;
    position: relative;
    top: 30px;
}

.toggleBtn {margin-top: 8px;}
